import type { RouteRecordRaw } from 'vue-router';

import { AUTH_ROLE } from '@vinicunca/kuli-entity';

import { LAYOUT_DEFAULT } from '~~/router/router.entity';

const systemRoute: RouteRecordRaw = {
  path: '/system',
  name: 'SystemManager',
  component: LAYOUT_DEFAULT,
  redirect: '/system/user',
  meta: {
    localIcon: 'users-black',
    order: 99,
    i18nKey: 'routes.system.management',
    title: 'System',
    roles: [AUTH_ROLE.ROOT],
  },
  children: [
    {
      path: 'user',
      name: 'UserManagement',
      component: () => import('~~/pages/system/users.vue'),
      meta: {
        localIcon: 'users-black',
        i18nKey: 'routes.system.user',
        title: 'User',
      },
    },
  ],
};

export default systemRoute;
