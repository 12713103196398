import type { RouteRecordRaw } from 'vue-router';

import { LAYOUT_DEFAULT } from '~~/router/router.entity';

const combineRoute: RouteRecordRaw = {
  path: '/combine',
  name: '/combine-root',
  component: LAYOUT_DEFAULT,
  meta: {
    icon: 'icon-mdi:flask-outline',
    order: 3,
    i18nKey: 'routes.combine',
    title: 'Combine',
  },
  children: [
    {
      path: '',
      name: '/combine',
      component: () => import('~~/pages/combine.vue'),
      meta: {
        title: 'Combine',
        hideInMenu: true,
      },
    },
  ],
};

export default combineRoute;
