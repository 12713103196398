import type { SkyFlavour, SkyStockOverview } from '@vinicunca/kuli-entity';

import { useQuery } from '@tanstack/vue-query';
import { FIRESTORE_COLLECTION } from '@vinicunca/kuli-entity';
import { type UpdateData, increment, orderBy, query } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import type { FirestoreData } from '~~/api/repositories/repository.types';

import { QUERY_KEY } from '~~/api/api.query';
import { parentProductRepo, productRepo } from '~~/api/repositories';
import { BaseRepository } from '~~/api/repositories/repository-factory';

export class FlavourRepository extends BaseRepository<SkyFlavour> {
  constructor() {
    super(FIRESTORE_COLLECTION.FLAVOURS);
  }

  queryOrderByName() {
    const querySnapshot = query(
      this.collectionRef,
      orderBy('typeLabel'),
    );

    return useQuery({
      queryKey: [QUERY_KEY.FLAVOURS_ORDER_BY_NAME],
      queryFn: () => this.executeQuery(querySnapshot),
      initialData: [],
    });
  }

  async addFlavour(data: FirestoreData<SkyFlavour>) {
    const id = uuidv4();

    await this.addDocument({
      data,
      id,
    });

    return id;
  }

  editFlavour(formFields: UpdateData<SkyFlavour>) {
    const { id, ...data } = formFields;

    return this.updateDocument({
      id: id as string,
      data,
    });
  }

  async increaseParentStockByOne(id: string) {
    try {
      await this.updateDocument({
        id,
        data: { 'stockCount.1': increment(1) },
      });
    } catch (error) {
      console.error('Error incrementing stock count:', error);
    }
  }

  async decreaseParentStockByOne(id: string) {
    try {
      await this.updateDocument({
        id,
        data: { 'stockCount.1': increment(-1) },
      });
    } catch (error) {
      console.error('Error incrementing stock count:', error);
    }
  }

  async increaseChildStockByOne(id: string, size: string) {
    const field = `stockCount.${size}`;
    try {
      await this.updateDocument({
        id,
        data: { [field]: increment(1) },
      });
    } catch (error: any) {
      console.error('Error incrementing child stock count:', error);
    }
  }

  async decreaseChildStockByOne(id: string, size: string) {
    const field = `stockCount.${size}`;
    try {
      await this.updateDocument({
        id,
        data: { [field]: increment(-1) },
      });
    } catch (error: any) {
      console.error('Error decreasing child stock count:', error);
    }
  }

  async getStockCount(id: string): Promise<SkyStockOverview> {
    const parents = await parentProductRepo.getTotalProducts(id);

    const products = await productRepo.getTotalBySize(id);

    return {
      parents,
      products,
    };
  }
}
