import type { RouteRecordRaw } from 'vue-router';

import { LAYOUT_DEFAULT } from '~~/router/router.entity';

const reportRoute: RouteRecordRaw = {
  path: '/report',
  name: '/report-root',
  component: LAYOUT_DEFAULT,
  meta: {
    order: 6,
    title: 'report',
    icon: 'icon-ion:document-outline',
    i18nKey: 'routes.report.root',
  },
  children: [
    {
      path: 'general-report',
      name: '/report/general-report',
      component: () => import('~~/pages/report/general-report.vue'),
      meta: {
        title: 'General Report',
        i18nKey: 'routes.report.general-report',
      },
    },
    {
      path: 'stock-report',
      name: '/report/stock-report',
      component: () => import('~~/pages/report/stock-report.vue'),
      meta: {
        title: 'Stock Report',
        i18nKey: 'routes.report.stock-report',
      },
    },
  ],
};

export default reportRoute;
