import type { SkyProductSize } from '@vinicunca/kuli-entity';

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { sizeRepo } from '~~/api/repositories';
import { useI18n } from '~~/locales';
import { SETUP_STORE_ID } from '~~/store/store.entity';

export type SkyProductSizesLabel = { label: string } & SkyProductSize;

type SkyProductSizesRecord = Record<
  string,
  SkyProductSizesLabel
>;

export const useProductStore = defineStore(SETUP_STORE_ID.PRODUCT, () => {
  const sourceSizes = ref<Array<SkyProductSize>>([]);

  const { $t } = useI18n();

  const sizes = computed<SkyProductSizesRecord>(() => {
    return sourceSizes.value.reduce<SkyProductSizesRecord>((acc, size) => {
      acc[size.id] = {
        ...size,
        label: $t(`product.sizes.${size.i18nKey}`),
      };

      return acc;
    }, {});
  });

  async function initSizes() {
    const data = await sizeRepo.getAll();
    sourceSizes.value = data;
  }

  return {
    sizes,
    initSizes,
  };
});

/**
 * Sizes references:
 * 1: Batch
 * 2: Joint
 * 3: Cake
 * 4: Finegrit
 * 5: Twigs
 * 6: Brownie
 * 7: 0.5 gr
 * 8: 1 gt
 * 9: 2 gr
 * 10: 3 gr
 * 11: 5 gr
 * 12: Joint shag
 * 13: Joint Cigarette
 * 14: None
 * 15: Polm cake
 * 16: Pure joint
 * 17: Mixed joint
 */
